import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../utils/AuthContext';
import { Button, Modal, Col } from 'react-bootstrap';

export default function DeleteEmployeeModal({ employeeId }) {
    const { deleteEmployee } = useContext(AuthContext);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = () => {
    handleClose();
    deleteEmployee(employeeId);
  };

  const handleClose = () => setShowDeleteConfirmation(false);
  const handleShow = () => setShowDeleteConfirmation(true);
  return (
    <Col>
      <Button className='w-100' variant='danger' onClick={handleShow}>
        <img src='./APPIcons/trash-fill.svg'></img>
      </Button>

      <Modal show={showDeleteConfirmation} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete employee?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will delete all employee content and data. This action is irreversible.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}
