import React, { useEffect, useContext, useState } from 'react';
import { Container, Row, Col, ListGroup, Form, Button } from 'react-bootstrap';
import { AuthContext } from '../../../utils/AuthContext';
import DeleteEmployeeModal from './helpers/DeleteEmployeeModal';
import EmployeeSettingsModal from './helpers/EmployeeSettingsModal';
import AddEmployeeModal from './helpers/AddEmployeeModal';

export default function EmployeeEditor() {
  const { getAllEmployees, allEmployees, db } = useContext(AuthContext);

  const [employeeReqs, setEmployeeReqs] = useState([]);

  const getAllEmployeeReqs = () => {
    return db
      .collection('employeeAcctReqs')
      .orderBy('requestSent')
      .onSnapshot((querySnapshot) => {
        let employeeArray = [];
        querySnapshot.forEach((doc) => {
          employeeArray.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setEmployeeReqs(employeeArray);
      });
  };

  const deleteReq = (id) => {
    return db.collection('employeeAcctReqs').doc(id).delete();
  };

  useEffect(() => {
    getAllEmployees();
    let unsubscribe = getAllEmployeeReqs();

    return () => unsubscribe();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={8}>
          <ListGroup className='w-100'>
            <ListGroup.Item key='1'>
              <Row>
                <Col>
                  <strong>Name</strong>
                </Col>
                <Col>
                  <strong>Email</strong>
                </Col>
                <Col sm={4}>
                  <strong>Settings</strong>
                </Col>
              </Row>
            </ListGroup.Item>
            {allEmployees.map((employee) => (
              <ListGroup.Item key={employee.id}>
                <Form>
                  <Row>
                    <Col>
                      {employee.data.fName} {employee.data.lName}
                    </Col>
                    <Col>{employee.data?.email}</Col>
                    <Col sm={4}>
                      <Row>
                        <EmployeeSettingsModal employee={employee} />
                        <DeleteEmployeeModal employeeId={employee.id} />
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col>
          <ListGroup className='w-100'>
            <ListGroup.Item key='1'>
              <Row>
                <Col>
                  <strong>Pending requests</strong>
                </Col>
                <Col className='text-right'>
                  <AddEmployeeModal />
                </Col>
              </Row>
            </ListGroup.Item>
            {employeeReqs.map((request, idx) => (
              <ListGroup.Item key={idx}>
                <Form>
                  <Row>
                    <Col>
                      {request.data.fName} {request.data.lName}
                    </Col>
                    <Col sm={3} className='text-right pl-0'>
                      <Button variant='link' id={request.id} className='p-0 text-danger' onClick={(e) => deleteReq(e.target.id)}>
                        delete
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{request.data.email}</Col>
                  </Row>
                </Form>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}
