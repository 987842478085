import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { AuthContext } from '../../utils/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { filteredRoutes } from '../routing/Routing';

export default function EmployeeNav() {
  const { employeeData, loading } = useContext(AuthContext);

  const [approvedLinks, setApprovedLinks] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLinks = (employeeData) => {
    const approvedLinks = filteredRoutes(employeeData.canAccess);
    if (approvedLinks.length < 1) navigate('/Careers');
    let redirect = true;
    approvedLinks.forEach((link) => {
      if (link.path === location.pathname) redirect = false;
    });
    if (redirect) navigate('/Careers');
    setApprovedLinks(approvedLinks);
  };

  useEffect(() => {
    !loading && employeeData?.canAccess && handleLinks(employeeData);
  }, [loading, employeeData]);

  return (
    <Navbar expanded={expanded} expand='xs' bg='light'>
      <Navbar.Brand className='pl-3'>
        Employee Portal <small className='pl-2'>{`${employeeData?.fName} ${employeeData?.lName}`}</small>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='employee-portal-navbar' onClick={() => setExpanded(!expanded)}/>
      <Navbar.Collapse id='employee-portal-navbar' className='justify-content-end px-3'>
        <Nav className='me-auto'>
          {approvedLinks.map((link) => (
            <div key={link.path}>
              <Nav.Link
                onClick={() => {
                  navigate(link.path);
                  setExpanded(false);
                }}
              >
                {link.linkName}
              </Nav.Link>
            </div>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
